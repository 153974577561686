import * as React from "react"
import Helmet from 'react-helmet'
import * as pageStyles from "./style.module.scss"

// font weights
import "@fontsource/poppins/200.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/700.css"

// markup
const NotFoundPage = () => {
  return (
    <main className={pageStyles.container}>
    <Helmet title="Chic Themes Club 404"/>
      <h1 className={pageStyles.errorHeading}>
        404
      </h1>

      <h3 className={pageStyles.summary}>Sorry, wrong turn. If you have a question, you can email me at <a href="mailto: hello@piyawillwerth.com">hello@piyawillwerth.com</a>.</h3>

      <h3 className={pageStyles.summary}><a href="/" title="Go back">Go Back</a></h3>

    </main>
  )
}

export default NotFoundPage
